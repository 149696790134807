import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App';
import './index.css';
import './utils/polyfillWithResolvers';

const isProd = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE === 'prod' || process.env.REACT_APP_STAGE === 'dev'
  : false;

Sentry.init({
  enabled: isProd,
  debug: !process.env.REACT_APP_STAGE,
  dsn: 'https://3c51042c698443c988296d6c338bf38a@o4505471840485376.ingest.sentry.io/4505471842254848',
  environment: process.env.REACT_APP_STAGE
    ? process.env.REACT_APP_STAGE === 'prod'
      ? 'production'
      : 'development'
    : 'localhost',
  allowUrls: ['home.solibo.no', 'rearden.dev.solibo.io'],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['solibo-home'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
  beforeSend: (event, hint) => {
    const exception = hint?.originalException;

    if (exception instanceof Response && exception.status === 401) {
      return null;
    }

    try {
      if (
        //@ts-ignore
        event._meta &&
        //@ts-ignore
        event._meta.isTrusted &&
        //@ts-ignore
        event._meta.isTrusted[''] &&
        //@ts-ignore
        event._meta.isTrusted[''].err &&
        //@ts-ignore
        event._meta.isTrusted[''].err.includes('invalid_attribute')
      ) {
        return null;
      }
    } catch (e) {}

    try {
      if (
        //@ts-ignore
        event.errors &&
        //@ts-ignore
        event.errors.some(
          //@ts-ignore
          (error) => error.type === 'invalid_attribute' && error.name === 'isTrusted'
        )
      ) {
        return null;
      }
    } catch (e) {}

    try {
      if (JSON.stringify(event) === '{"isTrusted":false}') {
        return null;
      }
    } catch (e) {}

    try {
      if (JSON.stringify(event.exception?.values!![0].value) === '{"isTrusted":false}') {
        return null;
      }
    } catch (e) {}

    try {
      if (event.exception?.values!![0].value === '{"isTrusted":false}') {
        return null;
      }
    } catch (e) {}

    if (event.exception && event.exception.values) {
      try {
        for (let ex of event.exception.values) {
          if (ex.mechanism && ex.mechanism.data && ex.mechanism.data.isTrusted === false) {
            return null;
          }
        }
      } catch (e) {}
    }

    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      const userId = event.user?.id;
      event.tags = event.tags || {};
      event.tags.userError = 'REPORT_WINDOW_OPEN';

      if (userId) {
        if (event.fingerprint) {
          event.fingerprint.push(`${userId}`);
        } else {
          event.fingerprint = ['{{ default }}', `${userId}`];
        }
      }

      const is504Error = exception instanceof Response && exception.status === 504;

      if (is504Error) {
        event.tags.errorType = '504_GATEWAY_TIMEOUT';
      } else {
        Sentry.showReportDialog({
          eventId: event.event_id,
          lang: 'nb',
          title: 'Her har det oppstått en feil!',
          subtitle:
            'Feilen er logget og sendt videre til våre utviklere. Hvis du vil bidra til en raskere løsning, fortell oss hva som skjedde nedenfor.',
          labelClose: 'Lukk',
          labelName: 'Navn',
          labelEmail: 'E-post',
          labelComments: 'Hva skjedde?',
          labelSubmit: 'Send',
          errorGeneric: 'En ukjent feil oppstod, vennligst prøv igjen.',
          errorFormEntry: 'En eller flere felter i skjemaet er feil, vennligst prøv igjen.',
          successMessage: 'Din tilbakemelding er levert. Takk!',
        });
      }
    }

    return event;
  },
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\/(?!\/)/],
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_STAGE === 'prod' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: 1.0,
});
Sentry.setTag('service', 'home-frontend');
Sentry.getCurrentScope().addEventProcessor((event) => {
  if (event.exception && event.exception.values) {
    try {
      for (let ex of event.exception.values) {
        if (ex.mechanism && ex.mechanism.data && ex.mechanism.data.isTrusted === false) {
          return null;
        }
      }
    } catch (e) {}
  }

  return event;
});

// hack for resolving remove issues with google translate
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // @ts-ignore
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.warn('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    // @ts-ignore
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // @ts-ignore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.warn(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    // @ts-ignore
    return originalInsertBefore.apply(this, arguments);
  };
}

if (process.env.REACT_APP_STAGE === 'prod') {
  console.log = () => {};
  console.debug = () => {};
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
