const local = {
  debug: true,
  domain: 'http://localhost:3000',
  securePrefix: 'secure',
  hjemmesidePrefix: 'hp',
  useDevCognitoPaths: true,
  multiBoligselskapPrefix: 'mine-boligselskaper',
  loginUri: '/auth/login',
  logoutUri: '/auth/logout',
  easterEggs: {
    christmasLights: false,
  },
  cognitoUserPoolId: 'jTVySlcSZ',
  cognitoUserPoolClientId: '1rhb7bjvmthh4qikac32933s7c',
  googleAnalytics: 'G-CGS3X1D65X',
  maxUploadSize: 1024 * 1024, // about 1 MB limit on local dev webpack
  mobileHapticFeedbackDurationMs: 10,
  stage: process.env.REACT_APP_STAGE,
  features: {
    homePage: {
      notifications: {
        email: true,
        sms: true,
      },
    },
    documents: {
      sok: true,
      mineFiler: true,
      filer: true,
      FDV: true,
      leverandorer: true,
      papirkurv: true,
    },
    settings: {
      ansvarsfordeling: true,
      sms: true,
      fakturavarsling: true,
    },
    economy: {
      utlegg: true,
      betalingsavtaler: true,
      status: true,
      rapport: true,
    },
    forvaltningsPortal: true,
    mobile: { hapticFeedback: true },
    nav: {
      root: true,
      profile: true,
      dashboard: true,
      homepageOpen: true,
      homepageSecure: true,
      messages: true,
      beboereAndBoliger: true,
      styret: true,
      praktiskInfo: true,
      oppslag: true,
      meetings: true,
      boardMatters: true,
      tasks: true,
      economy: true,
      leverandorer: true,
      boardNotes: false,
      operations: false,
      documents: true,
      settings: true,
      admin: true,
      adminDashboard: true,
      sendBrev: true,
      leverandorerAdmin: true,
      boligselskapAdmin: true,
      boligselskaperAdmin: true,
      boligerAdmin: true,
      personAdmin: true,
      organizationAdmin: true,
      personerAdmin: true,
      mergeAdmin: true,
      selskapsfordelingAdmin: true,
      eierskapAdmin: true,
      personnummerAdmin: true,
      settingsAdmin: true,
      papirfakturaAdmin: true,
      kunderegisterAdmin: true,
      innkrevingAdmin: true,
      innbetalingerAdmin: true,
      fakturainnstillingerAdmin: true,
      addBoligselskap: true,
      aboutBoligselskapet: true,
      forretningsforer: true,
      felleskostnader: true,
      massefakturering: true,
      oversikt: true,
      selskapsinfo: true,
      forsikring: true,
      lan: true,
      tredjepartsopplysninger: true,
      fakturainnstillinger: true,
      kunderegister: true,
      fakturering: true,
      hovedbok: false,
      SMS: true,
      newsletter: true,
      ehs: true,
      aarshjul: true,
      mineBoligselskaper: true,
      aktiverTriplewin: true,
    },
  },
};

// noinspection JSUnusedGlobalSymbols
const dev = {
  debug: false,
  domain: window.location.origin,
  securePrefix: 'secure',
  hjemmesidePrefix: 'hp',
  useDevCognitoPaths: false,
  multiBoligselskapPrefix: 'mine-boligselskaper',
  loginUri: '/auth/login',
  logoutUri: '/auth/logout',
  easterEggs: {
    christmasLights: false,
  },
  cognitoUserPoolId: 'jTVySlcSZ',
  cognitoUserPoolClientId: '1rhb7bjvmthh4qikac32933s7c',
  googleAnalytics: 'G-CGS3X1D65X',
  maxUploadSize: 1024 * 1024 * 1024, // 1 GB
  mobileHapticFeedbackDurationMs: 10,
  stage: process.env.REACT_APP_STAGE,
  features: {
    homePage: {
      notifications: {
        email: true,
        sms: true,
      },
    },
    economy: {
      utlegg: true,
      betalingsavtaler: false,
      status: true,
      rapport: true,
    },
    documents: {
      sok: true,
      mineFiler: true,
      filer: true,
      FDV: true,
      leverandorer: true,
      papirkurv: true,
    },
    settings: {
      ansvarsfordeling: true,
      sms: true,
      fakturavarsling: true,
    },
    forvaltningsPortal: true,
    mobile: { hapticFeedback: false },
    nav: {
      root: true,
      profile: true,
      dashboard: true,
      homepageOpen: true,
      homepageSecure: true,
      messages: true,
      beboereAndBoliger: true,
      styret: true,
      praktiskInfo: true,
      oppslag: true,
      meetings: true,
      boardMatters: true,
      tasks: true,
      economy: true,
      leverandorer: true,
      boardNotes: false,
      operations: false,
      documents: true,
      settings: true,
      admin: true,
      adminDashboard: true,
      sendBrev: true,
      leverandorerAdmin: true,
      boligselskapAdmin: true,
      boligselskaperAdmin: true,
      boligerAdmin: true,
      personAdmin: true,
      organizationAdmin: true,
      personerAdmin: true,
      mergeAdmin: true,
      eierskapAdmin: true,
      personnummerAdmin: true,
      settingsAdmin: true,
      papirfakturaAdmin: true,
      kunderegisterAdmin: true,
      innkrevingAdmin: true,
      innbetalingerAdmin: true,
      fakturainnstillingerAdmin: true,
      addBoligselskap: true,
      aboutBoligselskapet: true,
      forretningsforer: true,
      felleskostnader: true,
      massefakturering: true,
      oversikt: true,
      selskapsinfo: true,
      forsikring: true,
      lan: true,
      tredjepartsopplysninger: true,
      fakturainnstillinger: true,
      kunderegister: true,
      fakturering: true,
      hovedbok: false,
      SMS: true,
      newsletter: true,
      ehs: true,
      aarshjul: true,
      mineBoligselskaper: true,
      aktiverTriplewin: true,
    },
  },
};

// noinspection JSUnusedGlobalSymbols
const prod = {
  debug: false,
  domain: window.location.origin,
  securePrefix: 'secure',
  hjemmesidePrefix: 'hp',
  useDevCognitoPaths: false,
  multiBoligselskapPrefix: 'mine-boligselskaper',
  loginUri: '/auth/login',
  logoutUri: '/auth/logout',
  easterEggs: {
    christmasLights: false,
  },
  cognitoUserPoolId: 'RQ6mVI7Rc',
  cognitoUserPoolClientId: '5ubtos3pi1eoj6gu7nj1m1d0kh',
  googleAnalytics: 'G-CGS3X1D65X',
  maxUploadSize: 1024 * 1024 * 1024, // 1 GB
  mobileHapticFeedbackDurationMs: 10,
  stage: process.env.REACT_APP_STAGE,
  features: {
    homePage: {
      notifications: {
        email: true,
        sms: true,
      },
    },
    economy: {
      utlegg: true,
      betalingsavtaler: false,
      status: true,
      rapport: true,
    },
    documents: {
      sok: true,
      mineFiler: true,
      filer: true,
      FDV: true,
      leverandorer: true,
      papirkurv: true,
    },
    settings: {
      ansvarsfordeling: true,
      sms: true,
      fakturavarsling: true,
    },
    forvaltningsPortal: true,
    mobile: { hapticFeedback: false },
    nav: {
      root: true,
      profile: true,
      dashboard: true,
      homepageOpen: true,
      homepageSecure: true,
      messages: true,
      beboereAndBoliger: true,
      styret: true,
      praktiskInfo: true,
      oppslag: true,
      meetings: true,
      boardMatters: true,
      tasks: true,
      economy: true,
      leverandorer: true,
      boardNotes: false,
      operations: false,
      documents: true,
      settings: true,
      admin: true,
      adminDashboard: true,
      leverandorerAdmin: true,
      sendBrev: true,
      boligselskapAdmin: true,
      boligselskaperAdmin: true,
      boligerAdmin: true,
      personAdmin: true,
      organizationAdmin: true,
      personerAdmin: true,
      mergeAdmin: true,
      eierskapAdmin: true,
      personnummerAdmin: true,
      settingsAdmin: true,
      papirfakturaAdmin: true,
      kunderegisterAdmin: true,
      innkrevingAdmin: true,
      innbetalingerAdmin: true,
      fakturainnstillingerAdmin: true,
      addBoligselskap: true,
      aboutBoligselskapet: true,
      forretningsforer: true,
      felleskostnader: true,
      massefakturering: true,
      oversikt: true,
      selskapsinfo: true,
      forsikring: true,
      lan: true,
      tredjepartsopplysninger: true,
      fakturainnstillinger: true,
      kunderegister: true,
      fakturering: true,
      hovedbok: false,
      SMS: true,
      newsletter: true,
      ehs: true,
      aarshjul: true,
      mineBoligselskaper: true,
      aktiverTriplewin: true,
    },
  },
};

let calculatedConfig;

switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    calculatedConfig = dev;
    break;
  case 'prod':
    calculatedConfig = prod;
    break;
  case 'local':
  default:
    calculatedConfig = local;
}

const exportedConfig = { ...calculatedConfig };

export default exportedConfig;
